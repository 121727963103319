<template>
  <div class="cancel-order">
    <header>
      <div
        class="subheadline-large"
        :data-testid="`${testId}__title`"
        :data-cy="`${testId}__title`"
      >
        {{ $t("wallet.transaction.cancel.title") }}
      </div>

      <div
        class="body-text-large mt-s24"
        v-html="$t('wallet.transaction.cancel.message')"
      ></div>
    </header>

    <div class="flex flex-col items-end gap-s16 mt-s24">
      <ButtonV2
        class="capitalize"
        size="medium"
        :label="$tc('wallet.transaction.cancel.cancel')"
        testId="btn-cancel_transaction"
        @onClick="onRemove"
        wide
        version="destructive"
        :loading="loading"
        :data-testid="`${testId}__remove-order`"
        :data-cy="`${testId}__remove-order`"
      />

      <ButtonV2
        :label="$tc('wallet.transaction.cancel.close')"
        testId="btn-close"
        version="secondary"
        size="medium"
        wide
        @onClick="hideModal"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { ButtonV2 } from '@/components/misc';

export default {
  name: 'WalletTransactionOrderCancel',

  components: { ButtonV2 },

  props: {
    testId: {
      type: String,
      default: 'unamed',
    },
  },
  
  data() {
    return {
      loading: false
    };
  },

  computed: {
    ...mapGetters({ order: 'wallet/getSingleTransaction' }),
  },

  mounted() {
    this.setModalProps({
      hasPrevious: false,
      isClosable: false
    });
  },

  methods: {
    ...mapActions('ui', ['setModalProps']),
    ...mapActions('wallet',[
      'cancelActiveOrder',
      'retrieveSingleTransaction', 
      'resetTransactionHistory',
    ]),

    async onRemove() {
      this.loading = true;
      
      try {
        await this.cancelActiveOrder(this.order.id);
        await this.resetTransactionHistory();
        await this.retrieveSingleTransaction(this.$route.params.id);
        await this.showSuccess(this.$t('wallet.transaction.cancel.success'));
      } catch (err) {
        // that's fine
      } finally {
        this.loading = false;
        this.hideModal();
      }
    }
  }
};
</script>

<style scoped>
.cancel-order {
  @apply mt-s16 flex flex-col justify-between;
  height: 260px;
  max-height: 100%;
  max-width: 100%;
  width: 350px;
}
</style>
